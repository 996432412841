.sidebar-base {
    .sidebar-list {
        .navbar-nav{
            .nav-item{
                .nav-link{
                    .right-icon {
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                    &.active {
                        .right-icon {
                            -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }
                    }
                    &[aria-expanded=true] {
                        .right-icon {
                            -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        .navbar-nav{
            .nav-item{
                .nav-link:not(.disabled){
                    span {
                        margin-right: $spacer;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    &:not(.sidebar-mini){
        .navbar-nav{
            .nav-item{
                &:not(.static-item){
                    padding-left: unset;
                    padding-right: 1rem;
                }
            }
        }
    }
    &.navs-pill, &.navs-rounded{
        &:not(.sidebar-mini){
            .navbar-nav{
                .nav-item{
                    &:not(.static-item){
                        padding-right: unset;
                    }
                }
            }
        }
    }
    .data-scrollbar{
        .scrollbar-track-y{
            left: 0;
            right: unset;
        }
    }
}
.sidebar  {
  .sidebar-toggle {
      left: -12px;
      right: unset;
      transform: rotate(180deg);
  }
  .navbar-brand {
      margin-left: 1rem;
      margin-right: unset;
    .logo-title {
            margin-left: unset;
            margin-right: 1rem;
    }
  }
  &.sidebar-glass{
    border-left: 1px solid;
    border-right: unset;
}
}
.sidebar{
    &.sidebar-mini{
        .logo-mini {
            min-height: 30px;
            height: 30px;
            opacity: 1;
            transform: translateX(47px);
            transition: all 0.3s ease-in-out;
        }
        .logo-mini.dark-mini {
            opacity: 0
        }
    }
}

.dark {
    .logo-main{
        .logo-normal.dark-normal{
            min-height: 30px;
            height: 30px;
            opacity: 1;
            transform: translateX(120px);
            transition: all 0.3s ease-in-out;
        }
        .logo-mini.dark-mini{
            opacity: 0;
            min-height: 30px;
            height: 30px;
            transition: all 0.3s ease-in-out;
        }
    }
    .sidebar{
        &.sidebar-mini{
            .logo-mini.dark-mini {
                min-height: 30px;
                height: 30px;
                opacity: 1;
                transform: translateX(70px);
                transition: all 0.3s ease-in-out;
            }
            .logo-mini{
                opacity: 0;
            }
            .logo-normal {
                opacity: 0
            }
        }
    }
}

.offcanvas.offcanvas-end{
    left: 0 !important;
    right: unset;
    transform: translateX(-100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding){
    transform: none;
}